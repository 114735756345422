<!--
 * @Author       : Hugo
 * @Date         : 2020-05-21 13:37:50
 * @LastEditors  : Hugo
 * @LastEditTime : 2021-03-29 14:42:46
 * @Description  : 
 * @FilePath     : /miaohang/src/components/createDialog/selectUnit.vue
--> 
<template>
  <div class="create_dialog_select_unit cp" :class="{'on': on}" @click="doSelect">
    <div class="name_group">
      <img :src="`${$avatar_url}${userData.thumbnail}@!small200`" alt="" class="avatar">
      <div class="name" v-html="searchName ? searchName : userData.friendRemark"></div>
    </div>
    <div class="select_box" v-if="userData && (!userData.isInChat || is_single_dialog)">
      <div class="select cp" ></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    on:{
      type: Boolean,
      default: false,
    },
    userData: {
      type: Object,
      default: null,
    },
    father_index:{
      type: String,
      default: '',
    },
    child_index: {
      type: String,
      default: '',
    },
    searchName:{
      type: String,
      default: undefined,
    }
  },
  computed: {
    /**是否单聊页面 */
    is_single_dialog(){
      let routename = this.$route.name;
      if(routename == 'fun_task_dialog_single'){
        return true;
      }
      return false;
    },
  },
  watch: {
    on: function(newval){
      this.on = newval;
    },
    searchName: function(){}
  },
  methods:{
    doSelect(){
      this.$emit('doSelect',this.father_index, this.child_index)
    }
  },
  mounted(){
    // // console.log(this.userData)
  }
}
</script>
<style lang="scss" scoped>
.create_dialog_select_unit{
  @include bbox;
  @include flexsb;
  @include transition;
  // background-color: #fff;
  width:100%;
  padding: {
    // left: 37px;
    // right: 46px;
    // top: 11px;
    // bottom: 11px;
  }
  // &:hover{
  //   background-color:#f4f4f4;
  // }
  &.on{
    .select_box{
      .select{
        border:none;
        background-image: url(~@/assets/images/create_dialog/selected.png);
        background-size: 100%;
      }
    }
  }
  .name_group{
    @include flex;
    @include bbox;
    width:100%;
    flex: 1;
    padding-right: 15px;
    overflow: hidden;
    .avatar{
      width: 44px;
      height: 44px;
      border-radius: 4px;
      flex-shrink: 0;
    }
    .name{
      @include bbox;
      padding-left: 16px;
      width:100%;
      flex:1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size:14px;
      
      font-weight:400;
      color:rgba(51,51,51,1);
    }
  }
  .select_box{
    width:18px;
    height:18px;
    flex-shrink: 0;
    .select{
      @include bbox;
      // @include transition;
      width: 100%;
      height: 100%;
      border:2px solid rgba(153,153,153,1);
      border-radius:50%;
    }
  }
}
@media screen and (max-height: 800px){
.create_dialog_select_unit{
  .name_group{
    .avatar{
      width: 30px;
      height: 30px;
    }
  }
}
}
</style>