<!--
 * @Author       : Hugo
 * @Date         : 2021-04-12 11:11:35
 * @LastEditTime : 2021-11-09 14:58:32
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/MultipleContacts.vue
 * @Description  : [多选]联系人
 * @^_^          : 
-->
<template>
  <div
    class="multiple_contacts_page un_sel"
  >
    <div class="title_group">
      <div
        class="title"
      >
        分享至
      </div>
      <img
        src="@/assets/images/common/close.png"
        alt=""
        class="close cp"
        @click.stop="doClear"
      >
    </div>
    <div class="search_group">
      <search-box class=""></search-box>
    </div>
    <div class="main_group">
      <div class="select_group">
        <div class="main_box">
          <template
            v-if="if_lately"
          >
            <div
              class="title_bar un_sel cp"
              :class="{on: switch_lately}"
              @click="switch_lately=!switch_lately"
            >
              <div class="icon"></div>
              <div class="title">最近联系人</div>
            </div>
            <template
              v-if="switch_lately"
            >
              <template
                v-for="(item, index) in lately_list"
              >
                <div
                  v-if="item.chatId != $cm_id"
                  :key="`${index}lately`"
                  class="unit"
                  :class="{on: item.selected}"
                  @click="doSelect({id:item.chatId,type:item.chatType})"
                >
                  <img
                    :src="`${$avatar_url}${item.photo}@!small200`" alt="" class="avatar"
                  >
                  <div class="name">{{item.name}}</div>
                  <div class="select"></div>
                </div>
              </template>
              
            </template>
          </template>
        
          <template
            v-if="if_contacts"
          >
            <div
              class="title_bar un_sel cp"
              :class="{on: switch_contacts}"
              @click="switch_contacts=!switch_contacts"
            >
              <div class="icon"></div>
              <div class="title">联系人</div>
            </div>
            <template
              v-if="switch_contacts && !switch_search"
            >
              <div
                v-for="(item, index) in contacts_list"
                :key="`${index}contacts`"
                class="unit"
                :class="{on: item.selected}"
                @click="doSelect({id:item.friendUserId,type:0})"  
              >
                <img :src="`${$avatar_url}${item.thumbnail}@!small200`" alt="" class="avatar">
                <div class="name">{{item.friendRemark||item.userName}}</div>
                <div class="select"></div>
              </div>
            </template>
            <template
              v-if="switch_contacts && switch_search"
            >
              <div
                :data-b="item.friendUserId"
                v-for="(item, index) in search_contacts"
                :key="`${index}search_contacts`"
                class="unit"
                :class="{on: item.selected}"
                @click="doSelect({id:item.friendUserId,type:0})" 
              >
                <img :src="`${$avatar_url}${item.thumbnail}@!small200`" alt="" class="avatar">
                <div
                  class="name"
                  v-html="$tools.highlightKeywords(item.friendRemark||item.userName, keywords)"
                ></div>
                <div class="select"></div>
              </div>
            </template>
            <div class="no_data"
              v-if="no_contacts_data_was_searched"
            >没有数据</div>
          </template>
          <template
            v-if="if_teams"
          >
            <div
              class="title_bar un_sel cp"
              :class="{on: switch_teams}"
              @click="switch_teams=!switch_teams"
            >
              <div class="icon"></div>
              <div class="title">工作组</div>
            </div>
            <template
              v-if="switch_teams && !switch_search"
            >
              <div
                v-for="(item, index) in teams_list"
                :key="`${index}teams`"
                class="unit"
                :class="{on: item.selected}"
                @click="doSelect({id:item.chatId,type:1})"    
              >
                <img 
                  :src="`${$avatar_url}${item.photo}@!small200`" alt="" class="avatar">
                <div class="name">{{item.chatName}}</div>
                <div class="select"></div>
              </div>
            </template>
            <template
              v-if="switch_teams && switch_search"
            >
              <div
                v-for="(item, index) in search_teams"
                :key="`${index}search_teams`"
                class="unit"
                :class="{on: item.selected}"
                @click="doSelect({id:item.chatId,type:1})"    
              >
                <img 
                  :src="`${$avatar_url}${item.photo}@!small200`" alt="" class="avatar">
                <div
                  class="name"
                  v-html="$tools.highlightKeywords(item.chatName, keywords)"
                >{{item.chatName}}</div>
                <div class="select"></div>
              </div>
            </template>
            <div
              v-if="no_teams_data_was_searched"
              class="no_data"
            >
              没有数据
            </div>
          </template>
        </div>
      </div>
      <div
        class="selected_group"
      >
        <div 
          class="main_box"
          id="selected_box"
        >
          <div
            v-for="(item, index) in selected_list"
            :key="`${index}selected`"
            class="unit"
            ref="selected_unit"
          >
            <img :src="`${$avatar_url}${item.thumbnail}@!small200`" alt="" class="avatar">
            <div
              class="name"
            >
              {{ item.name }}
            </div>
            <div
              class="select del"
              @click="deleteSelected({ id:item.id, type:item.type })"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="btn_group"
    >
      <button
        class="button cp btn_dark"
        @click.stop="doSure"
      >
        确定
      </button>
      <button
        class="button cp btn_light"
        @click.stop="doClear"
      >
        取消
      </button>
    </div>
  </div>
</template>
<script>
import SearchBox from '@/components/basic/SearchBox.vue';
export default {
  props: {
    limit: {
      type: Number,
      default: 9,
    }
  },
  components: {
    SearchBox,
  },
  data(){
    return{ 
      switch_lately: true,
      switch_contacts: true,
      switch_teams: true,
      lately_list: [],
      contacts_list: [],
      teams_list: [],
      selected_list: [],
      // scrollto 配置
      options: {
        container: '#selected_box', //容器
        easing: 'ease-in',
        offset: 0,
        force: true,
        cancelable: true,
        onStart: function(element) {
          // scrolling started
        },
        onDone: function(element) {
          // scrolling is done
        },
        onCancel: function() {
          // scrolling has been interrupted
        },
        x: false,
        y: true
      },
      keywords: '',
      // 搜索工作组
      search_teams: [],
      // 搜索好友
      search_contacts: [],
      
      switch_search: false,
    }
  },
  computed: {
    if_lately(){
      const {lately_list, switch_search} = this;
      return Boolean(lately_list && lately_list.length && !switch_search);
    },
    if_contacts(){
      const {contacts_list} = this;
      return Boolean(contacts_list && contacts_list.length);
    },
    if_teams(){
      const {teams_list} = this;
      return Boolean(teams_list && teams_list.length);
    },
    no_contacts_data_was_searched(){
      const {switch_contacts, switch_search, search_contacts} = this;
      if(switch_search){
        if(switch_contacts && search_contacts.length == 0){
          return true;
        }
      }
      return false;
    },
    no_teams_data_was_searched(){
      const {switch_teams, switch_search, search_teams} = this;
      if(switch_search){
        if(switch_teams && search_teams.length == 0){
          return true;
        }
      }
      return false;
    },
    
  },
  methods: {
    doClose(){
      this.$store.commit('set_switch_multiple_contacts', false);

    },
    doClear(){
      const {next_switch_multiple_contacts} = this.$store.state;
      const {commit} = this.$store;
      this.doClose();
      if(next_switch_multiple_contacts){
        commit(`set_${next_switch_multiple_contacts}`,true); 
        return;
      }
     
      commit('set_multiple_contacts_list', null);
      commit('set_multiple_contacts_callback', null);
      commit('set_share_work', '');

    },
    /**
     * @description: 清除搜索
     * @param {*}
     * @return {*}
     */
    doClearSearch(){
      this.switch_search = false;
      this.keywords = '';
    },
    async doSearch (input) {
      if (!input) {
        this.keywords = ''
        this.switch_search = false
        return
      }
      this.keywords = input
      this.$showLoading({ text: '搜索中' })
      let res = await this.$global.doPost({
        url: '/project/searchWeb',
        data: { param: input },
        notice: '搜索失败'
      })
      this.$hideLoading()
      if (res.message === 'success') {
        this.switch_search = true
        let chatGroups = []
        let friends = []
        if (res.contents && res.contents.chatGoups) {
          chatGroups = res.contents.chatGoups
        }
        if (res.contents && res.contents.friends) {
          friends = res.contents.friends
        } 
        this.$set(this, 'search_teams', chatGroups)
        this.$set(this, 'search_contacts', [
          ...new Set([
            ...[],
            ...friends
          ])
        ])
        this.initSearchList()
      }
    },
    /**
     * @description:  确定 (父组件创建multipleContactsSure接收参数)
     * @param {*}
     * @return {*}
     */
    doSure () {
      const { commit, state } = this.$store
      const { selected_list } = this
      // const {multiple_contacts_callback} = state;
      if (selected_list.length == 0) {
        this.$notice({desc: '没有选择分享对象'})
        return
      }
      commit('set_multiple_contacts_list', selected_list)
      console.log(selected_list)
      commit('set_switch_share_confirm', true)
      this.doClose();
      // multiple_contacts_callback(selected_list);
      // this.$parent.multipleContactsSure(this.selected_list);
      // selected_list;
    },
    /**
     * @description: 最近列表
     * @param {*}
     * @return {*}
     */
    async getLatelyList () {
      const { message_box_list } = this.$store.state
      let res
      let lately_list = this.$tools.deepClone(message_box_list)
      if (!message_box_list || message_box_list.length === 0) {
        try {
          res = await this.$http.request('/projectTask/messageBoxList')
          if (res.message === 'success') {
            lately_list = res.contents.messageBox
          } else {
            lately_list = []
          }
        } catch (err) {
          lately_list = []
        }
      }
      this.$set(this, 'lately_list', lately_list)
    },
    /**
     * @description: 联系人列表
     * @param {*}
     * @return {*}
     */
    async getContactsList(){
      const {friends_list} = this.$store.state;
      let res ;
      let contacts_list = this.$tools.deepClone(friends_list);
      if(!friends_list || friends_list.length == 0){
        try{
          res = await this.$http.request('/projectFriends/friendsList');
          if(res.message == 'success'){
            contacts_list = res.contents.list;
          }else{
            contacts_list = [];
          }
        }catch(err){
          // console.log(err);
          contacts_list = [];
        }

      }
      this.$set(this, 'contacts_list', contacts_list);
      
    },
    /**
     * @description: 工作组列表
     * @param {*}
     * @return {*}
     */
    async getTeamList(){
      const {workteam_list} = this.$store.state;
      let res ;
      let teams_list = this.$tools.deepClone(workteam_list);
      if(!workteam_list || workteam_list.length == 0){
        try{
          res = await this.$http.request('projectFriends/chatGoupList');
          if(res.message == 'success'){
            teams_list = res.contents.list;
          }else{
            teams_list = [];
          }
        }catch(err){
          // console.log(err);
          teams_list = [];
        }

      }
      this.$set(this, 'teams_list', teams_list);
    },
    /**
     * @description: 初始化已选择列表
     * @param {*}
     * @return {*}
     */
    initSelectedList(){
      const {multiple_contacts_list} = this.$store.state;
      if(multiple_contacts_list && multiple_contacts_list.length){
        multiple_contacts_list.forEach(item => {
          this.doSelect({id: item.id, type: item.type});
        })
      }
    },
    /**
     * @description: 初始化搜索列表 
     * @param {*}
     * @return {*}
     */
    initSearchList(){
      const {selected_list} = this;
      if(selected_list.length){
        selected_list.forEach(item => {
          if(item.type == 0){
            this.selectSearchContact(item.id);
          }
          if(item.type == 1){
            this.selectSearchTeam(item.id);
          }
        })
      }
    },
    async init(){
      await this.getLatelyList();
      await this.getContactsList();
      await this.getTeamList();
      this.initSelectedList();
    },
    /**
     * @description: 点击选择项目
     * @param {*} id
     * @param {*} type
     * @return {*}
     */
    doSelect({id, type}){
      const {limit, selected_list} = this;
      if(selected_list && selected_list.length >= limit){
        this.$notice({desc: `最多可以选择${limit}个联系人`})
        return;
      }

      let aim;
      if(type == 0){
        aim = this.selectContact(id);
        this.selectSearchContact(id);
      }
      if(type == 1){
        aim = this.selectTeam(id);
        this.selectSearchTeam(id);
      }
      this.selectLately(id, type);

      this.changeSelected(aim);

    },
    /**
     * @description: 联系人[更新]
     * @param {*} id
     * @return {*}
     */
    selectContact(id){
      const {if_contacts, contacts_list} = this;
      let res;
      if(if_contacts){
        const aim = contacts_list.find(item=>{
          return item.friendUserId == id;
        })
        if(aim){
          this.$set(aim, 'selected', !(aim.selected));
          // this.changeSelected({id, type: 0, thumbnail: aim.thumbnail, name: aim.friendRemark || aim.userName});
          res =  {id, type: 0, thumbnail: aim.thumbnail, name: aim.friendRemark || aim.userName}
        }
      }

      return res;
    },
    /**
     * @description: 选择搜索联系人
     * @param {*} id
     * @return {*}
     */
    selectSearchContact(id){
      const {switch_search, search_contacts} = this;
      let res;
      if(switch_search && search_contacts.length){
        const aim = search_contacts.find(item => {
          return item.friendUserId == id;
        })
        if(aim){
          this.$set(aim, 'selected', !(aim.selected));
          res =  {id, type: 0, thumbnail: aim.thumbnail, name: aim.friendRemark || aim.userName}
        }
      }
      // // console.log(res);
      return res;
    },
    /**
     * @description: 工作组[更新]
     * @param {*} id
     * @return {*}
     */
    selectTeam(id){
      const {if_teams, teams_list} = this;
      let res;
      if(if_teams){
        const aim = teams_list.find(item=>{
          return item.chatId == id ;
        })
        if(aim){
          this.$set(aim, 'selected', !(aim.selected));
          // this.changeSelected({id, type: 0, thumbnail: aim.photo, name: aim.chatName});
          res = {id, type: 1, thumbnail: aim.photo, name: aim.chatName};
        }
      }
      return res;
    },
    /**
     * @description: 选择搜索工作组
     * @param {*} id
     * @return {*}
     */
    selectSearchTeam(id){
      const {switch_teams, search_teams} = this;
      let res;
      if(switch_teams && search_teams.length){
        const aim = search_teams.find(item=>{
          return item.chatId == id ;
        })
        if(aim){
          this.$set(aim, 'selected', !(aim.selected));
          res = {id, type: 1, thumbnail: aim.photo, name: aim.chatName};
        }
      }
      return res;
    },
    /**
     * @description: 最近项[更新]
     * @param {*} id
     * @param {*} type
     * @return {*}
     */
    selectLately(id, type){
      const {lately_list} = this;
      if(lately_list){
        const aim = lately_list.find(item=>{
          return item.chatId == id && item.chatType == type;
        })
        if(aim){
          this.$set(aim, 'selected', !(aim.selected));
        }
      }
    },
    /**
     * @description: 已选择列表[变更]
     * @param {*} id
     * @param {*} type
     * @param {*} thumbnail
     * @param {*} name
     * @return {*}
     */
    changeSelected({id, type, thumbnail, name}){
      const {selected_list} = this;
      const index = selected_list.findIndex(item=>{
        return id == item.id && type == item.type;
      })
      if(index == -1){
        selected_list.push({id, type, thumbnail, name});
        this.$nextTick(function(){
          this.scrollToBottom();
        })
      }else{
        selected_list.splice(index, 1);
      }
    },
    scrollToBottom(){
      const {selected_unit} = this.$refs;
      let element = selected_unit[selected_unit.length - 1];
        this.$scrollTo(element,1, this.options);
    },
    /**
     * @description: 已选择列表项[删除]
     * @param {*} id
     * @param {*} type
     * @return {*}
     */
    deleteSelected({id, type}){
      let aim;
      if(type == 0){
        aim = this.selectContact(id);
      }
      if(type == 1){
        aim = this.selectTeam(id);
      }
      this.selectLately(id, type);

      this.changeSelected(aim);

    },
    
  },
  async mounted(){
    this.init();
  },
}
</script>
<style lang="scss" scoped>
.multiple_contacts_page{
  @include flex;
  flex-direction: column;
  width: 80%;
  height: 90%;
  max-width: 1000px;
  max-height: 768px;
  background: #FFFFFF;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  .title_group{
    position: relative;
    flex-shrink: 0;
    font-size: 18px;
    color: #333333;
    padding-top: 20px;
    width: 100%;
    text-align: center;
    .close{
      // width: 20px;
      // height: 20px;
      position: absolute;
      top: 26px;
      right: 20px;

    }
  }
  .search_group{
    width: 100%;
    flex-shrink: 0;;
    padding-top: 20px;
    .search_box{
      max-width: 400px;
    }
    padding-bottom: 10px;
  }
  .main_group{
    @include bbox;
    @include flex;
    overflow: hidden;
    width: 100%;
    border: {
      top: 2px solid #f0f0f0;
      bottom: 2px solid #f0f0f0;
    }
    height: 100%;
    flex: 1;
    .select_group{
      border-right: 2px solid #f0f0f0;
      cursor: pointer;
    }
    .no_data{
      font-size: 14px;
      color: #999;
      width: 100%;
      text-align: center;
      padding: 10px 0;
    }
    .selected_group,.select_group{
      @include bbox;
      height: 100%;
      width: 50%;
      flex: 1;
      flex-shrink: 0;
      padding: 10px 0;
      overflow: hidden;
    }
  }
  .btn_group{
    @include flex;
    justify-content: center;
    width: 100%;
    flex-shrink: 0;
    padding: 15px 0;
    .button{
      font-size: 18px;
      border-radius: 4px;
      line-height: 50px;
      // padding: 0 50px;
      width: 160px;
      margin: 0 10px;
    }
  }
  .main_box{
    @include bbox;
    overflow-y: auto;
    height: 100%;
    padding: 0 20px;

  }
  .title_bar{
    @include flex;
    padding: 10px 0 20px;
    &.on{
      .icon{
        @include background(10px, 8px);
        background-image: url(~@/assets/images/common/multi_contacts_open.png);
      }
    }
    .icon{
      @include background(8px, 10px);
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/common/multi_contacts_close.png);
    }
    .title{
      font-size: 14px;
      color: #999;
    }
  }
  .unit{
    @include flex;
    @include bbox;
    width: 100%;
    overflow: hidden;
    padding-bottom: 10px;
    .avatar{
      width: 44px;
      height: 44px;
      border-radius: 4px;
      display: block;
      flex-shrink: 0
    }
    .select{
      @include bbox;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 2px solid #999;
      flex-shrink: 0;
      cursor: pointer;
      &.del{
        @include background;
        background-image: url(~@/assets/images/create_dialog/delete.png);
        border: none;
      }
    }
    .name{
      @include single_line_intercept;
      flex: 1;
      width: 100%;
      padding: {
        left: 10px;
        right: 15px;
      }
    }
    &.on{
      .select{
        @include background;
        background-image: url(~@/assets/images/create_dialog/selected.png);
        border: none;
      }
    }
  }
  
}
</style>